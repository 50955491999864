import './Contact.css';
import { FaDiscord, FaEnvelope } from 'react-icons/fa';

function Contact() {
    return (
        <div className="contact-page">
          <h1 style={{textAlign: 'center'}}><br />Kontaktiere Uns</h1>
          <div className="contact-info">
            <div className="contact-item">
              <FaDiscord className="contact-icon" />
              <div>
                <p>Über unseren Discord Server:</p>
                <a href="https://discord.gg/purgeesports" target="_blank" rel="noopener noreferrer">
                  https://discord.gg/purgeesports
                </a>
              </div>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <p>Über unsere Email:</p>
                <a href="mailto:business@prgpurge.com">
                  business@prgpurge.com
                </a>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Contact;